.container {
  composes: container from './LogosSection.module.css';
  margin: 7.5rem auto 6.25rem auto;
}

.title {
  composes: title from './LogosSection.module.css';
  font-size: 2.5rem;
  line-height: 2.75rem;
  letter-spacing: -0.02em;
}

.sectionTitle {
  composes: sectionTitle from './LogosSection.module.css';
  line-height: 1.125rem;
}

.logoSectionContainer {
  composes: logoSectionContainer from './LogosSection.module.css';
}

@media (max-width: 600px) {
  .partners {
    display: none;
  }
  .container {
    margin: 4rem 0 5rem 0;
  }
  .title {
    font-size: 1.75rem;
    line-height: 1.925rem;
    letter-spacing: -0.02em;
    margin-bottom: 2rem;
  }
  .logoSectionContainer {
    gap: 0;
    margin-bottom: 2rem;
  }
}

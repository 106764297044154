.container {
  composes: container from './HeroSection.module.css';
}

.flexboxFirst {
  composes: flexboxFirst from './HeroSection.module.css';
}

.flexboxSecond {
  composes: flexboxSecond from './HeroSection.module.css';
}

.flexboxFirst::after {
  content: ' ';
  position: absolute;
  background: url('/lp/assets/pl/hand.svg') no-repeat 0 0;
  background-size: contain;
}

.pricebox {
  composes: pricebox from './HeroSection.module.css';
  max-width: 264px;
}

.pricebox > h2 {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
}

.mainPrice {
  composes: mainPrice from './HeroSection.module.css';
  font-weight: 800;
  color: var(--accent-color);
}

.extra {
  composes: extra from './HeroSection.module.css';
}

h2.mainPrice {
  color: var(--accent-color);
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
}

.mainPrice,
.extra {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.date {
  composes: date from './HeroSection.module.css';
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 3%;
  margin-top: 32px;
}

.title {
  composes: title from './HeroSection.module.css';
  width: 70vw;
  max-width: 548px;
  color: #fff;
}

.description {
  composes: description from './HeroSection.module.css';
  max-width: 459px;
  color: #fff;
  width: 60vw;
}

.button {
  composes: button from './HeroSection.module.css';
}

.statistics {
  composes: statistics from './HeroSection.module.css';
}

@media (min-width: 990px) {
  .container {
    padding: 40px 80px 72px 80px;
    max-width: 1280px;
    grid-template-areas: 'text slider' 'statistics slider';
  }
}

@media (min-width: 1340px) {
  .flexboxFirst::after {
    display: block;
    aspect-ratio: 525 / 643;
    height: 643px;
    width: 525px;
    left: 420px;
    top: 32px;
  }
}

@media (max-width: 990px) {
  h2.mainPrice {
    font-size: 24px;
    line-height: 28px;
  }

  .date {
    margin-top: 0;
  }

  .title {
    font-size: 28px;
    line-height: 30.8px;
    width: 50vw;
    max-width: 190px;
  }

  .description {
    font-size: 15px;
    line-height: 18px;
    width: fit-content;
    max-width: 245px;
  }

  .title,
  .description {
    margin-bottom: 16px;
    word-break: break-word;
  }

  .pricebox {
    flex-direction: row;
    align-items: flex-end;
    gap: 7px;
  }

  .flexboxFirst::after {
    display: block;
    width: 147px;
    height: 181px;
    left: 66%;
    top: -22px;
  }
}

@media (max-width: 768px) {
  .button {
    width: 100%;
  }
}

.section {
  composes: section from './CollectionSection.module.css';
}

.background {
  composes: background from './CollectionSection.module.css';
  background-color: #fff;
}

.topBar {
  composes: topBar from './CollectionSection.module.css';
}

@media (max-width: 768px) {
  .topBar {
    gap: 2rem;
  }
  .section {
    padding-bottom: 16px;
  }
}

.containerWrapper {
  display: flex;
  background-color: var(--section-background-color);
}

.container {
  composes: container from './HeroSection.module.css';
}

.flexboxFirst {
  composes: flexboxFirst from './HeroSection.module.css';
  color: var(--primary-900-color);
}

.flexboxFirst::after {
  content: ' ';
  position: absolute;
  background: url('/lp/assets/landing-bw/background.svg') no-repeat 0 0;
  pointer-events: none;
}

.flexboxSecond {
  composes: flexboxSecond from './HeroSection.module.css';
}

.mainPrice {
  composes: mainPrice from './HeroSection.module.css';
  font-size: 2.25rem;
  line-height: 3rem;
  font-weight: 700;
}

.extra {
  composes: extra from './HeroSection.module.css';
}

.mainPrice,
.extra {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.priceDescription {
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.date {
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 3%;
  max-width: 490px;
  margin-top: 16px;
}

.title {
  composes: title from './HeroSection.module.css';
  max-width: 490px;
}

.description {
  composes: description from './HeroSection.module.css';
  max-width: 454px;
}

@media (min-width: 990px) {
  .container {
    padding: 40px 80px;
    max-width: 1280px;
    grid-template-areas: 'text slider' 'statistics slider';
  }
}

@media (min-width: 1440px) {
  .flexboxFirst::after {
    display: block;
    aspect-ratio: 679 / 1440;
    height: 679px;
    width: 1440px;
    left: -50px;
    top: -50px;
  }

  .date {
    margin-bottom: -8px;
  }
}

@media (max-width: 990px) {
  .mainPrice {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  .pricebox {
    align-items: flex-start;
    gap: 8px;
  }

  .flexboxSecond {
    margin: 32px 0;
  }
}

@media (max-width: 600px) {
  .flexboxFirst::after {
    display: block;
    background: url('/lp/assets/landing-bw/background_mobile.svg') no-repeat 0 0;
    right: -15px;
    top: 25px;
    aspect-ratio: 171 / 167;
    width: 171px;
    height: 167px;
  }

  .flexboxSecond {
    margin: 0 0 32px 0;
  }

  .description {
    font-size: 15px;
    line-height: 18px;
    width: fit-content;
    max-width: 260px;
  }
}

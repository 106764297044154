.textContainer {
  composes: textContainer from './PhoneSection.module.css';
  color: #fff;
}

.background {
  composes: background from './PhoneSection.module.css';
  position: relative;
  overflow-y: hidden;
  background-size: 640px 565px;
  background-position: left 100% top -110px;
  background-repeat: no-repeat;
}

.section {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.title {
  font-size: 40px;
  font-weight: 700;
}

.title,
.description {
  color: var(--primary-900-color);
}

.left {
  composes: left from './PhoneSection.module.css';
  gap: 48px;
}

@media (max-width: 990px) {
  .background {
    min-height: 971px;
    background-size: 596px 743px;
    background-position: left 100% bottom -140%;
  }
  .section {
    flex-direction: column;
    gap: 4rem;
  }
  .textContainer {
    gap: 2rem;
  }
  .title {
    font-size: 28px;
    line-height: 30.8px;
  }
  .description {
    font-size: 18px;
    line-height: 27px;
  }
  .left {
    gap: 2rem;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title {
}

.button {
  background-color: var(--accent-color);
  color: var(--text-accent-color);
  border-radius: 0.75rem;
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 700;
  letter-spacing: 0.09em;
  display: flex;
}

.left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .left {
    align-items: flex-start;
    flex-direction: column;
  }
}
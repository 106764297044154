.containerWrapper {
  display: flex;
  background-color: var(--section-background-color);
}

.container {
  cursor: pointer;
  overflow: hidden;
  margin: 0 auto;
  display: grid;
  grid-template-areas: 'text' 'slider' 'statistics';
  align-self: center;
  justify-content: space-between;
  height: fit-content;
  gap: 20px;
  padding: 32px 16px;
  grid-template-columns: 1fr;
}

.date {
  color: var(--accent-color);
}

.flexboxFirst,
.flexboxSecond {
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  background-size: cover;
  background-repeat: no-repeat;
  flex-basis: auto;
}

.flexboxFirst {
  padding: 64px;
  color: var(--hero-font-color);
  grid-area: text;
  text-align: left;
  box-shadow: none;
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  background-image: url('/assets/generic/hero_background.png');
}

.flexboxSecond {
  padding: 36px;
  color: var(--hero-right-color);
  grid-area: slider;
  margin-top: 0;
  background-size: cover;
  height: auto;
  border-radius: 16px;
  overflow: hidden;
  padding: 0;
  max-width: 600px;
  aspect-ratio: 343 / 161;
  background-image: url('/assets/generic/hero_background.png');
}

.pricebox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 0;
}

.title {
  letter-spacing: -0.03em;
  line-height: 3.25rem;
  font-size: 3rem;
  width: 70vw;
  font-weight: 700;
}

.description {
  font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: -0.02em;
  white-space: pre-wrap;
}

.mainPrice {
  font-size: 30px;
  font-weight: 500;
}

.button {
  width: fit-content;
  align-items: center;
  border-radius: 12px;
  margin-top: 8px;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 800;
  line-height: 16.5px;
  letter-spacing: 0.09em;
}

.extra {
  opacity: 0.7;
}

.secondaryText {
}

.statistics {
  grid-area: statistics;
}

@media (min-width: 990px) {
  .flexboxSecond {
    justify-self: flex-end;
    width: 350px;
    height: 476px;
  }
}

@media (min-width: 1200px) {
  .flexboxSecond {
    width: 412px;
    height: 560px;
  }
}

@media (min-width: 1440px) {
  .container {
    width: 1440px;
    grid-template-columns: minmax(450px, 500px) minmax(400px, 450px);
  }
}

@media (max-width: 990px) {
  .container {
    flex-direction: column;
    gap: 0;
    padding: 16px 16px 32px 16px;
  }

  .flexboxFirst {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 8px;
  }

  .flexboxSecond {
    margin-bottom: 24px;
    width: 100%;
    height: auto;
    aspect-ratio: 343 / 167;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: unset;
  }

  .date {
    margin-top: 0;
  }

  .title {
    font-size: 28px;
    line-height: 30.8px;
    width: 50vw;
    max-width: 190px;
  }

  .description {
    font-size: 16px;
    width: 70vw;
    max-width: 450px;
  }

  .title,
  .description {
    margin-bottom: 8px;
    word-break: break-word;
  }

  .pricebox {
    flex-direction: column;
    align-items: start;
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 600px) and (max-width: 1340px) {
  .flexboxFirst::after {
    display: none;
  }
}

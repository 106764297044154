.containerWrapper {
  display: flex;
  background-color: var(--section-background-color);
}

.container {
  composes: container from './HeroSection.module.css';
}

.flexboxFirst {
  composes: flexboxFirst from './HeroSection.module.css';
  color: var(--text-body-color);
}

.flexboxSecond {
  composes: flexboxSecond from './HeroSection.module.css';
}

.flexboxFirst::after {
  content: ' ';
  position: absolute;
  background: url('/lp/assets/pl/lips.svg') no-repeat 0 0;
  background-size: contain;
}

.mainPrice {
  composes: mainPrice from './HeroSection.module.css';
  font-size: 2.25rem;
  line-height: 3rem;
  font-weight: 700;
}

.extra {
  composes: extra from './HeroSection.module.css';
}

.mainPrice,
.extra {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.priceDescription {
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.date {
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 3%;
  max-width: 490px;
  margin-top: 16px;
}

.title {
  composes: title from './HeroSection.module.css';
  max-width: 490px;
}

.description {
  composes: description from './HeroSection.module.css';
  max-width: 490px;
}

.button {
  composes: button from './HeroSection.module.css';
  background-color: #e94d1d;
  margin-bottom: 0;
}

.button:hover {
  background-color: color-mix(in hsl, #e94d1d 90%, white 10%) !important;
}

@media (min-width: 990px) {
  .container {
    padding: 40px 80px;
    max-width: 1280px;
    grid-template-areas: 'text slider' 'statistics slider';
  }
}

@media (min-width: 1440px) {
  .flexboxFirst::after {
    display: block;
    aspect-ratio: 366 / 452;
    height: 452px;
    width: 366px;
    left: 430px;
    top: 75px;
  }

  .date {
    margin-bottom: -8px;
  }
}

@media (max-width: 990px) {
  .mainPrice {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  .pricebox {
    align-items: flex-start;
    gap: 8px;
  }

  .flexboxSecond {
    margin: 32px 0;
  }
}

@media (max-width: 600px) {
  .flexboxFirst::after {
    display: block;
    transform: scaleX(-1);
    right: -50px;
    top: 100px;
    aspect-ratio: 133 / 164;
    width: 133px;
    height: 164px;
  }
}

.section {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
  
.background {
  background-color: var(--section-background-color);
  width: 100%;
}

.title {
  text-transform: none;
}

.logo {
  width: 194px;
}

.left {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  justify-content: center;
  gap: 2rem;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  white-space: pre-wrap;
}

.right {

}

@media (max-width: 990px) {
  .section {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .right {
    align-self: center;
  }
}
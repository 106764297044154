.items {
  composes: items from './CollectionSection.module.css';
  margin-bottom: 0;
}

.items a {
  height: max-content;
  display: block;
}

.background {
  composes: background from './CollectionSection.module.css';
  background-color: #fff;
}

.title {
  composes: title from './CollectionSection.module.css';
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.75rem;
  letter-spacing: -0.02em;
  text-align: left;
}

@media (max-width: 768px) {
  .title {
    font-size: 1.75rem;
    line-height: 2rem;
  }
}

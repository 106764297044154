.container {
  display: flex;
  flex-direction: column;
  margin: 4rem auto;
}

.title {
  text-align: center;
  margin-bottom: 3rem;
}

.logoSectionContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
  text-align: center;
}

.logos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.logoImage {
  object-fit: contain;
  width: 88px;
  height: 64px;
}

.biggerLogoImage {
  object-fit: contain;
  width: 144px;
  height: 88px;
}

.sectionTitle {
  margin-bottom: 2rem;
}

@media (max-width: 600px) {
  .logoImage {
    width: 59px;
    height: 43px;
  }
}
